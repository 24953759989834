// RADON LEVEL RELATED
export const EPA_CONSIDER_FIXING_HOME = 2.7;
export const EPA_RECOMMEND_FIXING_HOME = 4.0;
export const EPA_CONSIDER_FIXING_HOME_BQ = 100;
export const EPA_RECOMMEND_FIXING_HOME_BQ = 150;
export const UNIT_CONVERSION_SCALE = 37;
export const NORMAL_STATUS = "normal";
export const CONSIDER_FIXING_STATUS = "consider_fixing";
export const RECOMMEND_FIXING_STATUS = "recommend_fixing";

// NETWORK RELATED
export const CRITICAL_ACTION_TIMEOUT = 30000;

// EVENT RELATED
export const ACTIVE = "ACTIVATE";
export const INACTIVE = "DEACTIVATE";
export const OVER4 = "Over 4pCi/L";
export const BELOW4 = "Below 4pCi/L";
export const ON = "on";
export const OFF = "off";
export const PCI = "pCi/L";
export const BQ = "Bq/m3";
export const UNIT_EVENT = "UNIT";
export const THRESHOLD_EVENT = "THRESHOLD";
export const ALARM_EVENT = "ALARM";
export const enableAlert = false;

// Monitoring mode
export const _24H_AVG = 24;
export const REALTIME = 1;
export const THRESHOLD_TO_SHOW = 24 * 60; // 24 hours in minutes

// Cognito attributes
export const CUSTOM_COMPANY_EMAIL = "custom:companyEmail";

// QR code scan
// issue report
export const ISS_WIFI = "Wi-Fi network connection failure";
export const ISS_OTHERS = "Other";

// states in the US
export const states = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  IA: "IA",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  MA: "MA",
  MD: "MD",
  ME: "ME",
  MI: "MI",
  MN: "MN",
  MO: "MO",
  MS: "MS",
  MT: "MT",
  NC: "NC",
  ND: "ND",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NV: "NV",
  NY: "NY",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VA: "VA",
  VT: "VT",
  WA: "WA",
  WI: "WI",
  WV: "WV",
  WY: "WY",
};

export const buildingType = {
  SF: "Single-family home",
  C1: "Commercial",
  E1: "Educational",
  H1: "Healthcare",
  G1: "Government",
  MF: "Multi-family home",
  P1: "Public Buildings",
};

// just comment added to push and on Jul. 31
// export const singleBuildingType = {
//   OF: "Office",
//   SC: "School",
//   HS: "House",
//   OT: "Other",
// };

export const singleBuildingType = {
  SF: "Single-family home",
  MA: "MF apartment",
  C1: "Commercial",
  SC: "School",
  DC: "Daycare",
  H1: "Healthcare",
  G1: "Government",
  P1: "Public Buildings",
  OT: "Other",
  OF: "Office",
  HS: "House"
};

// export const structureType = {
//   BS: "Basement",
//   SL: "Slab",
//   CR: "Crawlspace",
//   MS: "Multi-story",
// };

export const structureType = {
  BS: "Basement",
  CR: "Crawlspace",
  SL: "Slab-on-Grade",
  MS: "Combination",
  OT: "Other",
};

// export const testPurpose = {
//   RET: "Real estate transaction",
//   PRM: "Pre-mitigation",
//   PM: "Post-mitigation",
//   NC: "New construction",
//   OT: "Other",
//   undefined: "N/A",
// };

export const testPurpose = {
  RET: "Real estate transaction",
  NC: "New construction",
  OT: "Other",
  PRM: "Pre-mitigation",
  PM: "Post-mitigation",
  undefined: "N/A",
};

export const roomType = {
  LV: "Living room",
  DN: "Dining room",
  BD: "Bedroom",
  PL: "Playroom",
  BS: "Basement",
  OF: "Office",
  CS: "Classroom",
  OT: "Other",
};

export const closedBldgProtocol = {
  0: "No",
  1: "Yes",
  undefined: "N/A",
};

export const testFloor = {
  BS: "Basement",
  FL1: "1st Floor",
  FL2: "2nd Floor",
  "FL3+": "3rd Floor or above",
  undefined: "N/A",
};

export const mitigationSystem = {
  0: "Not Installed",
  1: "Installed",
  2: "Roughed-In",
  undefined: "N/A",
};

export const measurementDuration = {
  "48H": "48 hours",
  "72H": "72 hours",
  "96H": "96 hours",
};

export const delayTime = {
  "0H": "0 hours",
  "4H": "4 hours",
  "12H": "12 hours",
  "24H": "24 hours",
};

export const delayTimeNoun = {
  "0H": "no-hour",
  "4H": "4-hour",
  "12H": "12-hour",
  "24H": "24-hour",
};

export const measurementUnit = {
  0: "pCi/L",
  1: "Bq/m\u00B3",
};

export const measurementStatus = {
  0: "Ready",
  1: "In progress",
  2: "Finished",
  3: "Downloaded",
  4: "Uploaded",
  5: "Self-Finished",
};

export const statusColor = {
  0: "#DE7548",
  1: "#6BC04B",
  2: "#3DB5E6",
  3: "#4C46C1",
  4: "#4C46C1",
};

export const reportResult = {
  0: "≥ EPA",
  1: "< EPA",
};

export const reportResultColor = {
  0: "#DE7548",
  1: "#4CAF50",
};

export const duplicateTest = {
  0: "Standard",
  1: "Duplicate test (Original)",
  2: "Duplicate test (Duplicate)",
};

export const duplicateTestShorthand = {
  0: "N",
  1: "Y(O)",
  2: "Y(D)",
};

export const duplicateTestList = {
  0: "N",
  1: "Y(O)",
  2: "Y(D)",
};

export const wifiStatus = {
  0: "No",
  1: "Yes",
  2: "Failed to connect",
};

export const getAnomalyMsg = (anomalies) => {
  const anomalyMsg = {
    0: "0 pCi/L radon value detected. Apparent detector malfunction. A retest with another monitor is recommended",
    1: "Same radon value continuously detected. Please review it carefully and make your decision for the next steps.",
    2: "Radon values have increased(decreased) significantly. Apparent detector malfunction. A retest with another monitor is recommended.",
    3: "Apparent detector malfunction or detector is exposed to its maximum measurement range. A retest with another monitor is recommended.",
  };

  const indexOfAllAnomalies = anomalies.reduce(
    (acc, el, i) => (el === 1 ? [...acc, i] : acc),
    []
  );

  return indexOfAllAnomalies.map((index) => anomalyMsg[index]);
};

export const statesFullName = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "Wyoming",
  WY: "West Virginia",
};

export const reportResultSU = {
  0: "≥EPA Guidelines",
  1: "<EPA Guidelines",
};
